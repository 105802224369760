/* Marquee used on landing page */
.people_marquee {
  height: 600px;
  background-image: url("images/people3.jpg");
  background-size: cover;
  background-repeat: repeat;
  animation: marquee 60s infinite linear;
}

.vid-ps {
  background-image: url("images/ps-standbild.jpg")!important;
}
.vid-re {
  background-image: url("images/re-standbild.jpg")!important;
}
.vid-cz {
  background-image: url("images/cz-standbild.jpg")!important;
}
.vid-de {
  background-image: url("images/de-standbild.jpg")!important;
}

@keyframes marquee {
  0% {
    background-position: 0;
  }
  100% {
    background-position: -4000px;
  }
}

/* Custom link color in footer */
.footer-dark,
.footer-dark a {
  color: #999;
}

/* scroll snap on landing page */

/* ### ISSUES
sometimes, content is not vertically centered
if you scroll to the last step and you continue scrolling, the page is still fixed and nothing happens while scrolling
Currently, you have to get over a certain distance until the content starts to finish scrolling. The content should start scrolling even after a minimal scroll input by the user */
.scrollsnap-container {
  scroll-snap-type: y mandatory;
  overscroll-behavior-x: contain;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 600px;
}

.scrollsnap-container::-webkit-scrollbar {
  display: none;
}
.scrollsnap-container div {
  scroll-snap-align: start;
}

.h-hide:hover {
  opacity: 0 !important;
}

.interactive-banners-style-11 .interactive-banners-box .interactive-banners-text-content .detail {
  opacity: 0;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
}
.interactive-banners-style-11 .interactive-banners-box:hover .interactive-banners-text-content .detail {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
}

.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-text-overlay {
  opacity: 0.77;
}
.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-box-image img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
}
.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-text-content {
  transform: translateY(-65px);
  -webkit-transform: translateY(-65px);
  -moz-transform: translateY(-65px);
  -ms-transform: translateY(-65px);
}
.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-text-content .btn {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
}

/* // #################### Responsive */

@media (min-width: 1901px) {
}
@media (max-width: 1600px) {
}

@media (max-width: 1500px) {
}
@media (max-width: 1300px) {
  .dnb-1331 {
    display: none !important;
  }
}
@media (min-width: 1199px) {
}
@media (max-width: 1199px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
  .dnb-991 {
    display: none !important;
  }
  .herotitle {
    font-size: 4rem;
    line-height: 4rem;
  }
  .herosubtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .rmp-400 {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
} /* // navigation breakpoint */
@media (max-width: 778px) {
  .herotitle {
    font-size: 3rem;
    line-height: 3rem;
  }
  .herosubtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .rmpb-767 {
    margin: 0 !important;
    padding: 0 !important;
  }
  .rmb-767 {
    padding: 0 !important;
  }

  .am5b-767 {
    margin-top: 5rem;
  }
}
@media (max-width: 767px) {
  .panel-time,
  .panel-speaker {
    display: none !important;
  }
  .accordion-style-04 .panel .accordion-toggle {
    width: auto !important;
  }
  .accordion-style-04 .panel .panel-body {
    margin-left: 0 !important;
    width: auto;
  }
}
@media (max-width: 575px) {
  .herotitle {
    font-size: 1.6rem;
    line-height: 1.3rem;
    font-weight: 400 !important;
  }
  .herosubtitle {
    font-size: 2.4rem;
    line-height: 3.5rem;
    font-weight: 700;
  }
  .people_marquee {
    height: 400px !important;
  }
}
@media (max-width: 480px) {
}
@media (max-width: 575px) {
}

@media (max-width: 400px) {
  .herosubtitle {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 700;
  }
  .dnb-400 {
    display: none !important;
  }
}



.standortvideo {
  min-height: 100% !important;
  min-width: 100% !important;
}


.custom-carousel-container {
  overflow: hidden;
  width: 100%;
}

.custom-carousel {
  display: flex;
  width: 25%; /* Passen Sie die Breite entsprechend der Anzahl der Cards an */
}

.lh-20{
  line-height: 20px!important;
}




.portfolio-title {
  display: block;
  font-weight: 600;
  color: #333;
  font-size: 16px;
}

.portfolio-description {
  color: #666;
}

.big-capsule-section {
  background-image: url('/src/assets/images/capsules-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.navbar-blurry {
  background: rgba(255, 255, 255, 0.3)!important; /* Halbtransparent */
  backdrop-filter: blur(10px); /* Verschwommener Hintergrund */
  -webkit-backdrop-filter: blur(10px); /* Safari Unterstützung */

  transition: all 0.3s ease-in-out;
}